import { defineStore, storeToRefs } from "pinia";
import { values } from "lodash-es";
import useConfigStore from "./config";
import {
  type BookingPackagePayload,
  type BookingPaymentTypes,
  type BookingService,
  type BookingSpecialOccassion,
  type BookingSource,
  type BookingCreditCard,
  type BookingAdvPartner,
} from "~/types/Booking";
import { useRestaurantPackagesStore } from "~/stores/restaurant/restaurantPackages";
import type {
  Pack,
  PackagePayment,
  SpecialMenus,
  SelectedSpecialMenu,
  SpecialMenuPackages,
  SupportedPricingTypeSym,
} from "~/types/Pack";
import type { deliveryPricingTiersType } from "~/types/DeliveryPricingTier";
import {
  PACKAGE_CODE_HAH,
  PACKAGE_CODE_AYCE,
  SUPPORTED_PAYMENT_CREDIT_CARD,
  SUPPORTED_PAYMENT_PROMPT_PAY,
  SUPPORTED_PAYMENT_SHOPEE_PAY,
  SUPPORTED_PAYMENT_TRUE_WALLET,
  DELIVERY_METHOD_DELIVERY,
  DELIVERY_METHOD_PICKUP_AND_DELIVERY,
  DELIVERY_METHOD_PICKUP,
  PACKAGES_USING_TMA,
} from "~/constants";

import { useRestaurantDetailStore } from "~/stores/restaurantDetail";
import { useCorporateEventStore } from "~/stores/corporateEvent";
import dynamicPricingService from "~/services/package/dynamicPricing";

const useBookingStore = defineStore("booking", {
  state: () => {
    return {
      isLoading: false,
      isInCheckoutPage: false,
      isOrderNow: false,
      adult: 0,
      kids: 0,
      isBigGroup: false,
      bigGroupMinSize: 0,
      bigGroupMaxSize: 100, // 100 is not fixed value, since big group don't have fixed max value from the start
      bookingMethod: "dine_in" as BookingService, // possible value: dine_in / self_pickup / delivery
      paymentMethod: "" as BookingPaymentTypes, // possible value: card / promptpay / true_wallet  for package that require cc or '' if not require cc
      specialRequest: "",
      selectedDate: "",
      selectedTime: "",
      occasion: {
        attributes: {
          name: "",
        },
        id: "",
        type: "",
      } satisfies BookingSpecialOccassion,
      creditCard: {
        id: "",
        name: "",
        number: "",
        expMonthYear: "",
        securityCode: "",
        logo: "",
      } satisfies BookingCreditCard,
      acceptWeTravelTogether: false,
      temporaryBookingId: "",
      bookingExpiryDate: "",
      pointsToRedeem: 0,
      gbPrimePay: {},
      location: {
        addressId: "",
        name: "",
        address: "",
        detail: "",
        noteForDriver: "",
        contactName: "",
        contactNumber: "",
        lat: 0,
        lon: 0,
        primary: false,
        placeName: "",
      },
      orderNowEstimation: 0,
      isLoadingDeliveryInformation: false,
      pricingTier: [] as deliveryPricingTiersType[],
      channel: "",
      clickId: "",
      advPartner: "" as BookingAdvPartner,
      distanceToRestaurant: 0,
      isAcceptWeTravelTogether: false,
      preferPayNow: false,
      weTravelTogetherForm: {
        wttIdCard: "",
        wttPhone: "",
      },
      meta: {
        fbp: "",
        fbc: "",
      },
      source: "website" as BookingSource,
      selectedSpecialMenu: [] as SelectedSpecialMenu[],
      webV2Host: "",
      ticketTransactionId: "" as string | number,
      disableCheckoutButton: false,
      isShowAlert: false,
      invSource: null,
      sendAgreement: false,
      extendBooking: false,
      setAsDefaultPayment: false,
      changePrimaryCc: false,
      isDeleteCC: false,
    };
  },
  getters: {
    isDineIn(state): boolean {
      return state.bookingMethod === "dine_in";
    },
    shouldAcceptBigGroup(state): boolean {
      if (state.adult >= state.bigGroupMinSize && state.isBigGroup === false) {
        return true;
      }
      return false;
    },
    isValidWetravelTogether(state) {
      const bookingPackageStore = useBookingPackageStore();
      const isEmptyForm = values(state.weTravelTogetherForm).some(
        (key) => !key
      );
      const isPackageAcceptWeTravelTogether =
        bookingPackageStore.isPackageAcceptWeTravelTogether;

      if (!isPackageAcceptWeTravelTogether) return false;
      if (this.isAcceptWeTravelTogether && isEmptyForm) {
        return true;
      }
      return false;
    },
    isValidDeliveryLocation(state) {
      if (
        state.bookingMethod === "dine_in" ||
        state.bookingMethod === "pick_up"
      ) {
        return true;
      }
      if (
        !state.location.addressId &&
        !state.location.lat &&
        !state.location.lon
      ) {
        return false;
      }
      return true;
    },
    hasSelectedDateAndTime(state): boolean {
      return state.selectedDate.length > 0 && state.selectedTime.length > 0;
    },
    hasAdult(state): boolean {
      return state.adult > 0;
    },
    isAdultQtyMatchTheCustomSeats(state): boolean {
      const restaurantDetailStore = useRestaurantDetailStore();
      if (!restaurantDetailStore.isRestaurantHasCustomSeats) {
        return true;
      }
      if (state.isBigGroup) return true;
      const { customSeats } = restaurantDetailStore;
      const { adult } = state;
      if (Array.isArray(customSeats) && customSeats.length > 0) {
        const isMatch = customSeats.includes(adult);
        return isMatch;
      }
      return true;
    },
  },
});

const useBookingPackageStore = defineStore("bookingPackage", {
  state: () => {
    return {
      selectedPackages: [] as Pack[],
      selectedPackageId: "",
    };
  },
  getters: {
    selectedPackagesId(state) {
      return state.selectedPackages.map((pack) => pack.id);
    },
    selectedPackagesPayload(state): BookingPackagePayload {
      return state.selectedPackages.map((pack) => {
        const packageQuantity = () => {
          if (
            (pack.attributes.isAllowMix || this.isPackagesAllowedMix) &&
            state.selectedPackages.length > 1
          ) {
            return pack.attributes.quantity || 0;
          } else if (
            state.selectedPackages.length === 1 &&
            this.isPackagesAreAyce
          ) {
            return pack.attributes.quantity || 0;
          } else {
            return 1;
          }
        };
        const base = {
          id: pack.id,
          quantity:
            PACKAGES_USING_TMA.includes(pack.attributes.typeCode || "ayce") ===
            false
              ? packageQuantity()
              : pack.attributes.quantity,
          selectedSpecialMenus:
            pack.attributes.selectedSpecialMenus || undefined,
        };
        if (Array.isArray(pack.attributes.menuSections)) {
          const menuSections = pack.attributes.menuSections.map((section) => {
            return {
              id: section.id || "",
              name: section.name || "",
              quantityLimit: section.quantityLimit || 0,
              menus:
                section.menus
                  ?.filter((menu) => menu.quantity)
                  ?.map((menu) => {
                    return {
                      id: menu.id,
                      quantity: menu.quantity,
                    };
                  }) || [],
            };
          });
          return {
            ...base,
            menuSections,
          };
        }
        return base;
      });
    },
    isPackagesRequireCC(state): boolean {
      const packageRequireCc = state.selectedPackages.filter(
        (packages) => packages.attributes.requireCc === true
      );
      return packageRequireCc.length > 0;
    },
    isPackageAcceptWeTravelTogether(state): boolean {
      const packageAcceptTravelTogether = state.selectedPackages.filter(
        (packages) => packages.attributes.acceptWeTravelTogether
      );
      return packageAcceptTravelTogether.length > 0;
    },
    isPackagesAcceptVoucher(state): boolean {
      const packageAcceptVoucher = state.selectedPackages.filter(
        (packages) => packages.attributes.isAcceptVoucher === true
      );
      return packageAcceptVoucher.length > 0;
    },
    isPackagesSupportPayNow(state): boolean {
      const packageRequireCc = state.selectedPackages.filter(
        (packages) => packages.attributes.payNow === true
      );
      return packageRequireCc.length > 0;
    },
    getPackagesPayment(state): Set<PackagePayment> {
      const supportedPayment = new Set<PackagePayment>();
      state.selectedPackages.forEach((pack) => {
        const paymentTypes = pack.attributes.paymentTypes;
        if (Array.isArray(paymentTypes)) {
          if (paymentTypes.includes(SUPPORTED_PAYMENT_PROMPT_PAY)) {
            supportedPayment.add(SUPPORTED_PAYMENT_PROMPT_PAY);
          }
          if (paymentTypes.includes(SUPPORTED_PAYMENT_CREDIT_CARD)) {
            supportedPayment.add(SUPPORTED_PAYMENT_CREDIT_CARD);
          }
          if (paymentTypes.includes(SUPPORTED_PAYMENT_SHOPEE_PAY)) {
            supportedPayment.add(SUPPORTED_PAYMENT_SHOPEE_PAY);
          }
          if (paymentTypes.includes(SUPPORTED_PAYMENT_TRUE_WALLET)) {
            supportedPayment.add(SUPPORTED_PAYMENT_TRUE_WALLET);
          }
        }
      });
      return supportedPayment;
    },
    getPackagesType(): string {
      const selectedPackages = this.selectedPackages;
      if (selectedPackages.length) {
        return selectedPackages.map(
          (packages) => packages.attributes.typeCode || ""
        )[0];
      }
      return "";
    },
    isDineInPackages(): boolean {
      return this.getPackagesType !== PACKAGE_CODE_HAH;
    },
    isHAHPackages(): boolean {
      return this.getPackagesType === PACKAGE_CODE_HAH;
    },
    packagePricingTypeSym(state): SupportedPricingTypeSym | "" {
      if (
        !Array.isArray(state.selectedPackages) ||
        !state.selectedPackages.length
      )
        return "";
      const { pricingTypeSym } = state.selectedPackages[0].attributes;
      return (pricingTypeSym as SupportedPricingTypeSym) || "";
    },
    getSelectedPackageDeliveryType() {
      const selectedPackages = this.selectedPackages;
      const delivery = [];
      const pickup = [];
      const bothPickupAndDelivery = [];
      if (selectedPackages.length > 0) {
        selectedPackages.forEach((packages) => {
          if (packages.attributes.availableMethods !== undefined) {
            if (
              packages.attributes.availableMethods ===
              DELIVERY_METHOD_PICKUP_AND_DELIVERY
            ) {
              bothPickupAndDelivery.push(true);
            } else if (
              packages.attributes.availableMethods === DELIVERY_METHOD_DELIVERY
            ) {
              delivery.push(true);
            } else if (
              packages.attributes.availableMethods === DELIVERY_METHOD_PICKUP
            ) {
              pickup.push(true);
            }
          }
        });

        if (bothPickupAndDelivery.length > 0) {
          return DELIVERY_METHOD_PICKUP_AND_DELIVERY;
        } else if (delivery.length > 0 && pickup.length > 0) {
          return DELIVERY_METHOD_PICKUP_AND_DELIVERY;
        } else if (delivery.length > 0) {
          return DELIVERY_METHOD_DELIVERY;
        } else if (pickup.length > 0) {
          return DELIVERY_METHOD_PICKUP;
        } else {
          return DELIVERY_METHOD_PICKUP_AND_DELIVERY;
        }
      }
      return "";
    },
    getSpecialMenuPackages() {
      const configStore = useConfigStore();
      if (!configStore.backendConfig.features.enablePreselectMenu) {
        return [];
      }

      const selectedPackagesMenu = this.selectedPackages;
      const specialmenuPackages = [] as SpecialMenuPackages[];

      selectedPackagesMenu.forEach((item: Pack) => {
        const specialMenus = item.attributes.specialMenus ?? [];
        specialMenus.map((menu) => {
          if (menu.qty === undefined) {
            menu.qty = 0;
          }

          return menu;
        });

        let totalQty = 0;
        if (item.attributes.selectedSpecialMenus) {
          totalQty = item.attributes.selectedSpecialMenus.reduce(
            (total, menu) => {
              return total + (menu.quantity || 0);
            },
            0
          );
        }

        specialmenuPackages.push({
          id: item.id,
          packageName: item.attributes.name ?? "",
          enableSpecialMenu: item.attributes.enableSpecialMenu ?? false,
          specialMenuLimit: item.attributes.specialMenuLimit ?? 0,
          specialMenus: specialMenus ?? [],
          selectedQuantity: totalQty,
        });
      });

      return specialmenuPackages;
    },
    getSpecialMenuPackage() {
      let enableSpecialMenu = false;
      let specialMenuLimit = 0;
      let specialMenus = [] as SpecialMenus[];
      const configStore = useConfigStore();
      if (!configStore.backendConfig.features.enablePreselectMenu) {
        return {
          enableSpecialMenu,
          specialMenuLimit,
          specialMenus,
        };
      }

      const selectedPackages = this.selectedPackages;

      if (selectedPackages.length > 0) {
        selectedPackages.forEach((item) => {
          if (item.attributes.enableSpecialMenu) {
            enableSpecialMenu =
              typeof item.attributes.enableSpecialMenu === "boolean"
                ? item.attributes.enableSpecialMenu
                : false;
            specialMenuLimit = item.attributes.specialMenuLimit ?? 0;
            specialMenus = item.attributes.specialMenus ?? [];
          }
        });
      }

      return {
        enableSpecialMenu,
        specialMenuLimit,
        specialMenus,
      };
    },
    totalPackage(state): number {
      if (!this.hasSelectedPackage) {
        return 0;
      }
      if (state.selectedPackages[0].attributes.typeCode === PACKAGE_CODE_AYCE) {
        const bookingStore = useBookingStore();
        return bookingStore.adult + bookingStore.kids;
      }
      return state.selectedPackages.reduce((total, pkg) => {
        if (pkg.attributes.quantity !== undefined) {
          return total + (pkg.attributes.quantity || 0);
        }
        return total;
      }, 0);
    },
    hasSelectedPackage(state): boolean {
      return state.selectedPackages.length > 0;
    },
    isPackageHasTMA(): boolean {
      return PACKAGES_USING_TMA.includes(this.getPackagesType);
    },
    totalMaximumAllowance(): number {
      let total = 0;

      if (!this.isPackageHasTMA) return total;
      this.selectedPackages.forEach((pack) => {
        const { rules, quantity, dynamicPricing } = pack.attributes;
        if (dynamicPricing) {
          const { highestRuleByPerPack } =
            dynamicPricingService(dynamicPricing);
          const selectedRules = highestRuleByPerPack();
          if (selectedRules) {
            const amount =
              typeof selectedRules.perPack === "undefined" ||
              selectedRules.perPack === null
                ? 0
                : Number(selectedRules.perPack);

            total += amount * quantity;
          }
        } else if (Array.isArray(rules) && rules.length) {
          const lastRules = rules[rules.length - 1];
          const { perPack } = lastRules;
          if (typeof perPack === "number" && typeof quantity === "number") {
            const amount = perPack * quantity;
            total += amount;
          }
        }
      });
      return total;
    },
    minimumPartySize(): number {
      if (!this.hasSelectedPackage) return 0;
      if (this.isPackageHasTMA) return 0;
      const { rules, dynamicPricing } = this.selectedPackages[0].attributes;
      if (
        dynamicPricing &&
        dynamicPricing.type === "by_party_size" &&
        Array.isArray(rules) &&
        rules.length
      ) {
        const sortedRules = rules.sort((a, b) => {
          if (typeof a.minSeat === "string" && typeof b.minSeat === "string") {
            return Number(a.minSeat) - Number(b.minSeat);
          }
          return 0;
        });
        const lastRule = sortedRules[0];
        const { minSeat } = lastRule;
        return minSeat ? Number(minSeat) : 0;
      }
      if (Array.isArray(rules) && rules.length) {
        const lastRule = rules[rules.length - 1];
        const { minSeat } = lastRule;
        return minSeat ? +minSeat : 0;
      }
      return 0;
    },
    isShowPriceSummary(): boolean {
      const corporateEventStore = useCorporateEventStore();
      if (corporateEventStore.isValid) return false;
      return true;
    },
    isFulfillMinimumSeat(): boolean {
      if (this.isPackageHasTMA) return true;
      const bookingStore = useBookingStore();
      const restaurantDetailStore = useRestaurantDetailStore();
      const minimumPartySize = this.hasSelectedPackage
        ? this.minimumPartySize
        : restaurantDetailStore.minPartySize;
      return bookingStore.adult >= minimumPartySize;
    },
    isPackagesAllowedMix(): boolean {
      if (this.selectedPackages.length === 0) {
        return false;
      }

      if (this.selectedPackages.length > 1 && this.isPackagesAreAyce) {
        return true;
      }

      return this.selectedPackages.every(
        (pack) =>
          pack.attributes.typeCode === PACKAGE_CODE_AYCE &&
          pack.attributes.isAllowMix === true
      );
    },
    isPackagesAreAyce(): boolean {
      return this.selectedPackages.every(
        (pack) => pack.attributes.typeCode === PACKAGE_CODE_AYCE
      );
    },
    countQuantityOfPackagesAyce(): number {
      return this.selectedPackages.reduce((total, pack) => {
        if (
          pack.attributes.typeCode === PACKAGE_CODE_AYCE &&
          pack.attributes.isAllowMix
        ) {
          return total + (pack.attributes.quantity || 0);
        } else if (pack.attributes.typeCode === PACKAGE_CODE_AYCE) {
          return total + (pack.attributes.quantity || 0);
        }
        return total;
      }, 0);
    },
  },
  actions: {
    removeAllSelectedPackages() {
      this.selectedPackages = [];
    },
    canMixPackage(newPackageId: string): boolean {
      const restaurantPackagesStore = useRestaurantPackagesStore();
      const { packages } = storeToRefs(restaurantPackagesStore);
      const bookingStore = useBookingStore();
      if (this.selectedPackages.length > 0) {
        const newPkg = packages.value.filter(
          (item) => item.id === newPackageId
        )[0];
        const newPkgType = newPkg.attributes.typeCode;
        const newPkgId = newPkg.id;
        const currentPkgType = this.selectedPackages[0].attributes.typeCode;
        const currentPkgId = this.selectedPackages[0].id;
        const currentPkgTypes = this.selectedPackages.map(
          (item) => item.attributes.typeCode
        );

        if (!currentPkgTypes.includes(newPkgType)) {
          return false;
        }

        if (newPkgId !== currentPkgId && newPkgType === PACKAGE_CODE_AYCE) {
          return newPkg.attributes.isAllowMix || false;
        }

        if (newPkgId === currentPkgId && newPkgType === PACKAGE_CODE_AYCE) {
          return newPkg.attributes.isAllowMix || false;
        }

        if (
          currentPkgType === PACKAGE_CODE_AYCE &&
          newPkgType === PACKAGE_CODE_AYCE &&
          currentPkgId !== newPkgId
        ) {
          return newPkg.attributes.isAllowMix || false;
        }
        if (currentPkgTypes.includes(newPkgType)) {
          if (newPkg.attributes.isAcceptManyQuantity) {
            const currentPkgContainNotAllowMix = this.selectedPackages.filter(
              (pack) => {
                return !pack.attributes.isAllowMix;
              }
            );
            if (
              currentPkgContainNotAllowMix.length > 0 ||
              !newPkg.attributes.isAllowMix
            ) {
              return false;
            }
          }
        }
        if (this.isPackageHasTMA && bookingStore.isBigGroup) {
          return false;
        }
      }
      const findPackage = packages.value.find(
        (pack) => pack.id === newPackageId
      );
      return findPackage?.attributes.isAllowMix || false;
    },
  },
});

const useBookingDateTimeStore = defineStore("bookingDateTime", {
  state: () => {
    return {
      preferedOrderTime: "", // possible value: order-later / order-now
      isTodayAvailableForBooking: false,
      todayFastestBookingDate: "",
      todayFastestBookingTime: "",
    };
  },
});

export { useBookingStore, useBookingPackageStore, useBookingDateTimeStore };
