import { defineStore } from "pinia";
import type {
  Pack,
  SupportedPackageType,
  PackageCardProps,
} from "~/types/Pack";
import { useReport } from "~/composables/useReport";
import {
  DEFAULT_ERROR_MESSAGE,
  PACKAGE_CODE_AYCE,
  PACKAGE_CODE_HAH,
} from "~/constants";
import alert from "~/lib/alert";
import {
  determineBookable,
  formatPackageCard,
  determineExpired,
} from "~/helpers/pack";
import { useBookingStore } from "~/stores/booking";
import { createReactivePack } from "~/services/package/package";
import dayjs from "~/lib/dayjs";

type PackagesByType = Record<
  SupportedPackageType,
  Record<string, PackageCardProps[]>
>;

const useRestaurantPackagesStore = defineStore("restaurantPackages", {
  state: () => {
    return {
      isLoading: false,
      packages: [] as Pack[],
      availablePackagesId: [] as (string | number)[],
      packageToView: {} as Pack,
      packagesByType: {} as PackagesByType,
      reviewPackages: [] as Pack[],
      labelActivePackages: [] as SupportedPackageType[],
      isLoadingCheckDateTime: false,
    };
  },
  getters: {
    isHavePackages(): boolean {
      return this.packages.length > 0;
    },
    selectedPackages(): Pack[] {
      return this.packages.filter((pack) => pack.attributes.quantity > 0);
    },
    isAllPackagesExpired(state): boolean {
      const dateNow = dayjs().format("YYYY-MM-DD");

      return state.packages.every((pack) => {
        if (
          pack.attributes.startDate &&
          dateNow >= pack.attributes.startDate &&
          pack.attributes.endDate &&
          dateNow <= pack.attributes.endDate
        ) {
          return false;
        }
        return true;
      });
    },
  },
  actions: {
    doFormatPackageCard({
      packages,
      isRestaurantExpired,
      isCorporateBooking,
    }: {
      packages: Record<string, Pack[]>;
      isRestaurantExpired: boolean;
      isCorporateBooking: boolean;
    }) {
      const formatedPackages: Record<string, PackageCardProps[]> = {};
      const { adult, kids } = useBookingStore();
      for (const groupName in packages) {
        formatedPackages[groupName] = [];
        packages[groupName].forEach((pack) => {
          const packageQuantity = () => {
            if (!pack.attributes.isAllowMix) {
              return adult + kids;
            } else {
              return pack.attributes.quantity;
            }
          };
          const packageCard = formatPackageCard(pack);

          packageCard.bookable = determineBookable(
            pack.id,
            this.availablePackagesId
          );
          packageCard.isExpired = determineExpired(
            pack.attributes.endDate || "",
            isRestaurantExpired
          );
          packageCard.description = pack.attributes.description || "";
          packageCard.allowShowPrice = !isCorporateBooking;
          packageCard.isLoading = false;
          packageCard.isPackageAvailable = true;
          packageCard.quantity =
            packageCard.type === PACKAGE_CODE_AYCE &&
            packageCard.quantity &&
            packageCard.quantity > 0
              ? packageQuantity()
              : packageCard.quantity;
          formatedPackages[groupName].push(packageCard);
          packageCard.menuButton = {
            isOpen: false,
          };
        });
      }
      return formatedPackages;
    },
    async getPackages(param: {
      isVisibleForStaff: boolean;
      restaurantId: string | number;
    }) {
      try {
        this.isLoading = true;
        const { useCorporateEventStore } = await import(
          "~/stores/corporateEvent"
        );
        const { getRestaurantPackages } = await import(
          "~/api/restaurant/getRestaurantPackagaes"
        );
        const { useRestaurantDetailStore } = await import(
          "~/stores/restaurantDetail"
        );
        const { usePackageTicketStore } = await import(
          "~/stores/packagetTicket"
        );
        const { useBookingPackageStore } = await import("~/stores/booking");
        const useConfigStore = (await import("~/stores/config")).default;
        const corporateEventStore = useCorporateEventStore();
        const restaurantDetailStore = useRestaurantDetailStore();
        const packageTickeStore = usePackageTicketStore();
        const configStore = useConfigStore();
        const { selectedPackages } = useBookingPackageStore();
        const { eventId, isValid: isValidEvent } = corporateEventStore;
        const { isSuccess, message, data } = await getRestaurantPackages({
          restaurantId: param.restaurantId,
          isVisibleForStaff: param.isVisibleForStaff === true ? true : null,
          corporateEventId: isValidEvent ? eventId : null,
          mock: configStore.mockData.includes("package") === true ? true : null,
        });
        if (!isSuccess || !data) {
          if (message) {
            useReport({
              level: "error",
              message,
            });
            alert.error(message);
          }
          return;
        }
        this.packages = data.map((pack) => createReactivePack(pack)) || [];

        if (
          !this.isAllPackagesExpired ||
          !packageTickeStore.isAllPackageTicketsExpired
        ) {
          const dateNow = dayjs().format("YYYY-MM-DD");

          this.packages = this.packages.filter((pack) => {
            if (
              pack.attributes.startDate &&
              dateNow >= pack.attributes.startDate &&
              pack.attributes.endDate &&
              dateNow <= pack.attributes.endDate
            ) {
              return true;
            }
            return false;
          });
        }
        for (const selectedPackage of selectedPackages) {
          const itemToUpdate = this.packages.find(
            (item) => item.id === selectedPackage.id
          );
          if (itemToUpdate) {
            itemToUpdate.attributes.quantity =
              selectedPackage.attributes.quantity;
            if (itemToUpdate.attributes.typeCode === PACKAGE_CODE_HAH) {
              itemToUpdate.attributes.menuSections?.forEach((section) => {
                if (section.menus) {
                  section.menus.forEach((menu) => {
                    const correspondingMenu =
                      selectedPackage.attributes.menuSections
                        ?.find(
                          (selectedSection) => selectedSection.id === section.id
                        )
                        ?.menus?.find(
                          (selectedMenu) => selectedMenu.id === menu.id
                        );

                    menu.quantity = correspondingMenu?.quantity || 0;
                  });
                }
                section.totalQuantityLimit =
                  (section.quantityLimit || 0) *
                  (itemToUpdate.attributes.quantity || 0);
                section.totalSelectedMenu =
                  section.menus?.reduce(
                    (acc, menu) => acc + (menu.quantity || 0),
                    0
                  ) || 0;
              });
            }
          }
        }
        const { splitPackagesByType } = await import("~/helpers/pack");
        const { hah, ayce, bfp, hs, pp, sm, xp } = splitPackagesByType(
          this.packages
        );
        if (Object.values(ayce).some((arr) => arr.length > 0)) {
          this.packagesByType.ayce = this.doFormatPackageCard({
            packages: ayce,
            isCorporateBooking: isValidEvent,
            isRestaurantExpired: restaurantDetailStore.isRestaurantExpired,
          });
          if (!this.labelActivePackages.includes("ayce")) {
            this.labelActivePackages.push("ayce");
          }
        }
        if (Object.values(hah).some((arr) => arr.length > 0)) {
          this.packagesByType.hah = this.doFormatPackageCard({
            packages: hah,
            isCorporateBooking: isValidEvent,
            isRestaurantExpired: restaurantDetailStore.isRestaurantExpired,
          });
          if (!this.labelActivePackages.includes("hah")) {
            this.labelActivePackages.push("hah");
          }
        }
        if (Object.values(bfp).some((arr) => arr.length > 0)) {
          this.packagesByType.bfp = this.doFormatPackageCard({
            packages: bfp,
            isCorporateBooking: isValidEvent,
            isRestaurantExpired: restaurantDetailStore.isRestaurantExpired,
          });
          if (!this.labelActivePackages.includes("bfp")) {
            this.labelActivePackages.push("bfp");
          }
        }
        if (Object.values(hs).some((arr) => arr.length > 0)) {
          this.packagesByType.hs = this.doFormatPackageCard({
            packages: hs,
            isCorporateBooking: isValidEvent,
            isRestaurantExpired: restaurantDetailStore.isRestaurantExpired,
          });
          if (!this.labelActivePackages.includes("hs")) {
            this.labelActivePackages.push("hs");
          }
        }
        if (Object.values(pp).some((arr) => arr.length > 0)) {
          this.packagesByType.pp = this.doFormatPackageCard({
            packages: pp,
            isCorporateBooking: isValidEvent,
            isRestaurantExpired: restaurantDetailStore.isRestaurantExpired,
          });
          if (!this.labelActivePackages.includes("pp")) {
            this.labelActivePackages.push("pp");
          }
        }
        if (Object.values(sm).some((arr) => arr.length > 0)) {
          this.packagesByType.sm = this.doFormatPackageCard({
            packages: sm,
            isCorporateBooking: isValidEvent,
            isRestaurantExpired: restaurantDetailStore.isRestaurantExpired,
          });
          if (!this.labelActivePackages.includes("sm")) {
            this.labelActivePackages.push("sm");
          }
        }
        if (Object.values(xp).some((arr) => arr.length > 0)) {
          this.packagesByType.xp = this.doFormatPackageCard({
            packages: xp,
            isCorporateBooking: isValidEvent,
            isRestaurantExpired: restaurantDetailStore.isRestaurantExpired,
          });
          if (!this.labelActivePackages.includes("xp")) {
            this.labelActivePackages.push("xp");
          }
        }
        const { packageTypeOrder } = await import("~/helpers/pack");
        this.labelActivePackages = packageTypeOrder(this.labelActivePackages);
        this.isLoading = false;
      } catch (err) {
        const message = `${DEFAULT_ERROR_MESSAGE}, failed get restaurant packages`;
        useReport({
          level: "critical",
          message,
          errorException: err,
        });
      }
    },
    async adjustPackageQuantity({
      type,
      packageId,
      quantity,
    }: {
      type: "increase" | "decrease";
      packageId: string;
      quantity?: number;
    }) {
      const index = this.packages.findIndex((item) => item.id === packageId);
      if (index === -1) {
        throw new TypeError("Package not found");
      }

      if (
        this.packages[index].attributes.isAllowMix &&
        this.packages[index].attributes.typeCode === PACKAGE_CODE_AYCE
      ) {
        // Handle the case where the package is an AYCE package and is allowed to mix.
        const { useBookingPackageStore } = await import("~/stores/booking");
        const { useBookingStore } = await import("~/stores/booking");

        const bookingStore = useBookingStore();
        const bookingPackageStore = useBookingPackageStore();

        const findSeletedPackage = bookingPackageStore.selectedPackages.find(
          (pack) => pack.id === packageId
        );
        if (findSeletedPackage) {
          this.packages[index].attributes.quantity--;
          findSeletedPackage.attributes.quantity =
            this.packages[index].attributes.quantity;
          if (findSeletedPackage.attributes.quantity === 0) {
            const removedIndexPackage =
              bookingPackageStore.selectedPackages.findIndex(
                (pack) => pack.id === packageId
              );
            if (removedIndexPackage === -1) {
              // Handle the case where the package with the specified packageId is not found.
              return;
            }
            bookingPackageStore.selectedPackages.splice(removedIndexPackage, 1);
            bookingStore.adult =
              bookingPackageStore.countQuantityOfPackagesAyce;
            return;
          }

          if (bookingPackageStore.selectedPackages.length === 0) {
            return;
          }

          if (
            bookingPackageStore.selectedPackages.length > 1 &&
            bookingPackageStore.isPackagesAreAyce
          ) {
            // handle the case where adult is greater than total quantity of packages.
            if (
              bookingStore.adult >
              bookingPackageStore.countQuantityOfPackagesAyce
            ) {
              bookingStore.adult =
                bookingPackageStore.countQuantityOfPackagesAyce;
            }

            if (
              bookingStore.adult !==
              bookingPackageStore.countQuantityOfPackagesAyce
            ) {
              bookingStore.disableCheckoutButton = true;
              bookingStore.isShowAlert = true;
            } else {
              bookingStore.disableCheckoutButton = false;
              bookingStore.isShowAlert = false;
            }
          } else if (
            bookingPackageStore.selectedPackages.length === 1 &&
            bookingPackageStore.isPackagesAreAyce
          ) {
            bookingStore.adult =
              bookingPackageStore.countQuantityOfPackagesAyce;
            bookingStore.disableCheckoutButton = false;
            bookingStore.isShowAlert = false;
          } else {
            bookingStore.adult =
              bookingPackageStore.countQuantityOfPackagesAyce;
          }
        }
        return;
      }
      if (typeof quantity === "number") {
        this.packages[index].attributes.quantity = quantity;
        return;
      }
      if (type === "increase") {
        this.packages[index].attributes.quantity =
          (this.packages[index].attributes.quantity || 0) + 1;
        return;
      }
      this.packages[index].attributes.quantity =
        (this.packages[index].attributes.quantity || 0) - 1;
    },
    removeAllSelectedPackages() {
      this.packages.forEach((pack) => {
        pack.attributes.quantity = 0;
      });
    },
    resetPackageByType(type: SupportedPackageType, id?: string | number) {
      const packages = this.packagesByType[type];
      Object.values(packages).forEach((packageCard) => {
        packageCard.forEach((pack) => {
          if (id && pack.id === id) {
            pack.quantity = 0;
            if (pack.menuButton) pack.menuButton.isOpen = false;
          } else if (!id) {
            pack.quantity = 0;
            if (pack.menuButton) pack.menuButton.isOpen = false;
          }
        });
      });
    },
    resetAllPackageByType(id?: string | number) {
      Object.keys(this.packagesByType).forEach((type) => {
        this.resetPackageByType(type as SupportedPackageType, id);
      });
    },
    setLoadingPackageByType(state: boolean) {
      this.isLoadingCheckDateTime = state;
      Object.keys(this.packagesByType).forEach((type) => {
        Object.values(
          this.packagesByType[type as SupportedPackageType]
        ).forEach((packageCard) => {
          packageCard.forEach((pack) => {
            pack.isLoading = state;
          });
        });
      });
    },
    async setAvailabilityPackageByType() {
      const { useBookingPackageStore } = await import("~/stores/booking");
      const bookingPackageStore = useBookingPackageStore();
      // do not continue if user has selected package
      // this function only select date time first
      if (bookingPackageStore.hasSelectedPackage) return;
      Object.keys(this.packagesByType).forEach((type) => {
        Object.values(
          this.packagesByType[type as SupportedPackageType]
        ).forEach((packageCard) => {
          packageCard.forEach((pack) => {
            if (this.availablePackagesId.length > 0) {
              pack.isPackageAvailable = this.availablePackagesId.includes(
                pack.id
              );
            } else {
              pack.isPackageAvailable = true;
            }
          });
        });
      });
    },
    resetAvailabilityPackageByType() {
      Object.keys(this.packagesByType).forEach((type) => {
        Object.values(
          this.packagesByType[type as SupportedPackageType]
        ).forEach((packageCard) => {
          packageCard.forEach((pack) => {
            pack.isPackageAvailable = true;
          });
        });
      });
    },
  },
});

export { useRestaurantPackagesStore };
