import { convertToNumber } from "~/helpers/string";
import type { DynamicPricing } from "~/types/Pack";
import dayjs from "~/lib/dayjs";

export default function dynamicPricing(dynamicPricing: DynamicPricing) {
  const containKidsPrice = dynamicPricing?.rules?.some(
    (rule) => typeof rule.kidsPriceCents === "number" && rule.kidsPriceCents > 0
  );

  const byHolidayPrice = ({ reservationDate }: { reservationDate: string }) => {
    const currentDay = dayjs(reservationDate, "YYYY-MM-DD").startOf("day");

    if (!currentDay.isValid()) {
      throw new Error("Invalid reservation date");
    }

    const holidays = dynamicPricing.rules.filter(
      (rule) => rule.categoryName === "holiday"
    );
    if (holidays.length > 0) {
      const matchedPrice = holidays.filter((rule) => {
        const startDate = dayjs(rule.startDate, "YYYY-MM-DD").startOf("day");
        const endDate = dayjs(rule.endDate, "YYYY-MM-DD").endOf("day");
        return (
          startDate.isValid() &&
          endDate.isValid() &&
          currentDay.isBetween(startDate, endDate, "day", "[]")
        );
      });
      return matchedPrice.length > 0 ? matchedPrice[0].price : 0;
    }
    return 0;
  };

  const byDayPrice = ({ reservationDate }: { reservationDate: string }) => {
    const currentDay = dayjs(reservationDate, "YYYY-MM-DD").startOf("day");
    const currentDayFormat = currentDay.format("dddd");

    if (!currentDay.isValid()) {
      throw new Error("Invalid reservation date");
    }

    if (dynamicPricing.type === "by_day") {
      if (
        Array.isArray(dynamicPricing.rules) &&
        dynamicPricing.rules.length > 0
      ) {
        const filtered = dynamicPricing.rules.filter((rule) => {
          return rule.days?.includes(currentDayFormat);
        });
        return filtered.length > 0 ? filtered[0].price : 0;
      }
      return 0;
    }
    return 0;
  };

  const byPaxSizePrice = ({ adult }: { adult: number }) => {
    if (
      Array.isArray(dynamicPricing.rules) &&
      dynamicPricing.rules.length > 0
    ) {
      const filteredRules = dynamicPricing.rules.filter((rule) => {
        if (
          typeof rule.minSeat === "string" &&
          rule.minSeat.length &&
          typeof rule.maxSeat === "string" &&
          rule.maxSeat.length
        ) {
          const parsedMinSeat = parseInt(rule.minSeat);
          const parsedMaxSeat = rule.maxSeat.includes("infinity")
            ? 9999
            : parseInt(rule.maxSeat);
          return adult >= parsedMinSeat && adult <= parsedMaxSeat;
        }
        return false;
      });
      return filteredRules.length > 0 ? filteredRules[0].price : 0;
    }
    return 0;
  };

  const lowetRuleByPrice = () => {
    if (
      Array.isArray(dynamicPricing.rules) &&
      dynamicPricing.rules.length > 0
    ) {
      return dynamicPricing.rules.sort((a, b) => {
        return convertToNumber(a.price) - convertToNumber(b.price);
      })[0];
    }
    return null;
  };

  const highestRuleByPax = () => {
    if (
      Array.isArray(dynamicPricing.rules) &&
      dynamicPricing.rules.length > 0
    ) {
      return dynamicPricing.rules.sort((a, b) => {
        const bMaxSeat =
          typeof b.maxSeat !== "string"
            ? 0
            : b.maxSeat === "infinity"
            ? 9999
            : parseInt(b.maxSeat);
        const aMaxSeat =
          typeof a.maxSeat !== "string"
            ? 0
            : a.maxSeat === "infinity"
            ? 9999
            : parseInt(a.maxSeat);
        return convertToNumber(bMaxSeat) - convertToNumber(aMaxSeat);
      })[0];
    }
    return null;
  };

  const highestRuleByPerPack = () => {
    if (
      Array.isArray(dynamicPricing.rules) &&
      dynamicPricing.rules.length > 0
    ) {
      return dynamicPricing.rules.sort((a, b) => {
        const bPerPack =
          typeof b.perPack !== "string"
            ? 0
            : b.maxSeat === "infinity"
            ? 9999
            : parseInt(b.perPack);
        const aPerPack =
          typeof a.perPack !== "string"
            ? 0
            : a.perPack === "infinity"
            ? 9999
            : parseInt(a.perPack);
        return convertToNumber(bPerPack) - convertToNumber(aPerPack);
      })[0];
    }
    return null;
  };

  const lowestPrice = () => {
    return lowetRuleByPrice()?.price || 0;
  };

  const finalPrice = ({
    reservationDate,
    adult,
  }: {
    reservationDate: string;
    adult?: number;
  }) => {
    if (
      dynamicPricing.type === "by_party_size" ||
      dynamicPricing.type === "normal"
    ) {
      if (typeof adult === "number") {
        return byPaxSizePrice({ adult }) || lowestPrice();
      }
      return lowestPrice();
    }
    return (
      byHolidayPrice({ reservationDate }) || byDayPrice({ reservationDate })
    );
  };

  return {
    containKidsPrice,
    lowestPrice,
    byDayPrice,
    finalPrice,
    lowetRuleByPrice,
    highestRuleByPax,
    highestRuleByPerPack,
  };
}
